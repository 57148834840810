<template>
  <div class="sync-scroll-box" >
    <div class="title-box">
      <div class="title">
        <h5>{{$t("label.layers")}}</h5>
        <a href="javascript:" class="flatbutton layer" @click="open('layer')">
          <span class="glyphicons glyphicons-plus"></span>
        </a>
      </div>
      <div class="title">
        <h5>{{$t("label.soilSamples")}}</h5>
        <a href="javascript:" class="flatbutton soil-sample" @click="open('soilSample')">
          <span class="glyphicons glyphicons-plus"></span>
        </a>
      </div>
      <div class="title">
        <h5>{{$t("label.gaugingTubes")}}</h5>
        <a href="javascript:" class="flatbutton gauging-tube" @click="open('gaugingTubes')">
          <span class="glyphicons glyphicons-plus"></span>
        </a>
      </div>
    </div>
    <div class="scroll-body" data-simplebar>
      <!--<boxes v-for="item in boxesData" :options="{
        title: item.title,
        type: item.type,
        businesskey: item.businesskey,
        modal: item.modal,
        data: item.data,
        PrID: options.PrID,
        MpGuid: options.MpGuid,
        showAddButton: true,
        enableBoxLink: true}">
        </boxes>-->
      <boxes :options="{
      type:'layerBox',
      businesskey:'layer',
      modal:'laModal',
      data: options.layer,
      PrID: options.PrID,
      isfieldRequest: true,
      fieldname: 'LaSoilType',
      isMultiple: true,
      MpGuid: options.MpGuid,
      enableBoxLink: true,
      disableSort: true,}"></boxes>
        <boxes :options="{
      type:'soilSampleBox',
      businesskey:'soilSample',
      modal:'saModal',
      data: options.soilSample,
      PrID: options.PrID,
      MpGuid: options.MpGuid,
      enableBoxLink: true,
      disableSort: true,}"></boxes>
          <boxes :options="{
      type:'gaugingTubesBox',
      businesskey:'gaugingTubes',
      modal:'gaModal',
      border: options.gaugingTubes.length>0 ?'border' : '',
      data: options.gaugingTubes,
      PrID: options.PrID,
      MpGuid: options.MpGuid,
      enableBoxLink: true,
      disableSort: true,}"></boxes>
    </div>
  </div>
</template>
<script>
import boxes from '../boxes.vue'
export default {
  name: 'syncScroll',
  components: {
    boxes
  },
  props: ['template', 'functions', 'options'],
  data () {
    return {
      classes: '',
      boxesData: []
    }
  },
  methods: {
    open (type) {
      /* $('.' + type + '.flatbutton')[0].click(); */
      let box = this.$children.find(v => v.options.businesskey === type)
      box.open()
    }
  }
}
</script>
<style>
  .sync-scroll-box .scroll-body {
    height: calc(100vh - 210px);
    overflow-y: auto
  }
</style>
<style lang="less">
  .sync-scroll-box {
    float: left;
    padding-top: 10px;
    .title-box {
      overflow: hidden;
      .title {
        padding-left: 10px;
        float: left;
        width: 180px;
        +.title {
          margin-left: 20px
        }
        h5 {
          margin: 0 0 20px 0
        }
        a.flatbutton {
          transition: 0.5s;
          &:hover,
          &:focus {
            transform: scale(0.90);
          }
          &.layer {
            background: #793F11
          }
          &.soil-sample {
            background: #C7B39A
          }
          &.gauging-tube {
            background: #07828a
          }
        }
      }
    }
    .scroll-body {
      .mpboxs {
        padding-top: 0;
        .title-box {
          height: 0;
          opacity: 0
        }
        .contentbox {
          overflow: initial;
          height: initial
        }
      }
    }
  }
</style>
