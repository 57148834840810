<template>
  <div id="modalPrintToScale" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">{{$t("printToScaleModal.title")}}</h4>
        </div>
        <div class="modal-body">
          <!-- Content -->
          <form class="form print-to-scale-form">
            <div>
              <label>{{$t("printToScaleModal.introLabel")}}</label>
              <div>{{$t("printToScaleModal.introText")}}</div>
            </div>
            <br>
            <label for="format">{{$t("printToScaleModal.format")}}</label>
            <select id="format" v-model="format" class="form-control">
              <!-- <option :value="'a0'">A0</option>
              <option :value="'a1'">A1</option>
              <option :value="'a2'">A2</option> -->
              <option :value="'a3'">A3</option>
              <option :value="'a4'">A4</option>
              <option :value="'a5'">A5</option>
            </select>
            <label for="resolution">{{$t("printToScaleModal.resolution")}}</label>
            <select id="resolution" v-model="resolution" class="form-control">
              <option :value="150">150 dpi</option>
              <option :value="200">200 dpi</option>
              <option :value="300">300 dpi</option>
            </select>
            <div class="form-group" :class="{'has-error': hasError}">
              <label for="scale">{{$t("printToScaleModal.scale")}}</label>
              <span class="scale">
                <div class="scale-indicator">1:</div>
                <div class="w-100">
                  <input id="scale" v-model="scale" class="form-control scale-input" placeholder="1000" />
                  <div v-if="hasError" class="help-block">{{$t("printToScaleModal.scaleRequiredValidation")}}</div>
                </div>
              </span>
            </div>
            <div>{{$t("map.SaveMapImageHelp", {0: fileName})}}</div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn" @click="save" >{{loadingText}}</button>
          <button class="btn" @click="close" >{{cancelText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { bus } from '@/bus.js'
import config from '@/configurations/config.js'
import Popup from '@/utils/popup'
import { getPointResolution } from 'ol/proj'
import domtoimage from 'dom-to-image-more'
import JsPdf from 'jspdf'
import moment from 'moment'
import { MediaService } from '@/services/MediaWS/mediaService'
const mediaService = new MediaService()

let popup = Popup[config.platform].default

let northArrowImg = new Image()
northArrowImg.src = window.location.protocol + '//' + window.location.host + config.root + 'assets/img/map_icons/north_arrow.png'

export default {
  name: 'printToScaleModal',
  props: ['functions', 'option'],

  data () {
    return {
      loading: false,
      uploaded: false,
      pdf: null,
      mapRef: null,

      hasError: false,

      format: 'a4',
      resolution: 200,
      scale: null,
      fileName: '',

      // translations of 'AX' formats to mm dimensions (minus 20mm to account for print margins)
      dimensions: {
        a0: [1169, 821],
        a1: [821, 574],
        a2: [574, 400],
        a3: [400, 277],
        a4: [277, 190],
        a5: [190, 128]
      },

      // export options for html-to-image.
      // See: https://github.com/bubkoo/html-to-image#options
      exportOptions: {
        filter: function (element) {
          var className = element.className || ''
          return (
            className.indexOf('ol-control') === -1 ||
            className.indexOf('ol-scale') > -1 ||
            (className.indexOf('ol-attribution') > -1 &&
              className.indexOf('ol-uncollapsible'))
          )
        }
      }
    }
  },

  computed: {
    projects: {
      get () {
        return this.$store.state.projects
      },
      set (value) {
        this.$store.commit('setProjects', value)
      }
    },
    loadingText: {
      get () {
        if (this.uploaded) return this.$t('other.download')
        else return this.loading ? (this.$t('other.loading') + '...') : this.$t('map.SaveButton')
      }
    },
    cancelText: {
      get () {
        if (this.uploaded) return this.$t('other.close')
        else return this.$t('map.CloseButton')
      }
    }
  },

  mounted () {
    let self = this
    bus.$on('printToScaleEvent', function (mapRef) {
      self.mapRef = mapRef
      self.fileName = `map_${moment(new Date()).format('YYYYMMDD_kkmmss')}.pdf`
      $('#modalPrintToScale').modal('show')
    })
  },

  methods: {
    clearData () {
      this.loading = false
      this.uploaded = false
      this.pdf = null

      this.mapRef = null

      this.format = 'a4'
      this.resolution = 200
      this.scale = null
      this.hasError = false
      this.fileName = ''
    },

    save () {
      if (this.uploaded) {
        this.pdf.save(`map_${moment(new Date()).format('YYYYMMDD_kkmmss')}.pdf`)
        this.close()
        return false
      }

      if (this.loading) {
        return false
      }
      this.hasError = !this.scale
      if (this.hasError) {
        return false
      }

      this.loading = true
      // find project guid
      let project = this.projects.find((pr) => { return pr.PrID === this.$route.params.projectId })
      if (!project) {
        popup.popup(this.$t('map.MapImageError'))
        return false
      }

      const dims = this.dimensions[this.format]
      const width = Math.round((dims[0] * this.resolution) / 25.4)
      const height = Math.round((dims[1] * this.resolution) / 25.4)
      const viewResolution = this.mapRef.getView().getResolution()
      const scaleResolution = (this.scale / 1000) / getPointResolution(this.mapRef.getView().getProjection(), this.resolution / 25.4, this.mapRef.getView().getCenter())

      const self = this
      this.mapRef.once('rendercomplete', function () {
        self.exportOptions.width = width
        self.exportOptions.height = height

        domtoimage.toJpeg(self.mapRef.getViewport(), self.exportOptions)
          .then(function (dataUrl) {
            const pdf = new JsPdf('landscape', undefined, self.format)
            pdf.addImage(dataUrl, 'JPEG', 10, 10, dims[0], dims[1]) // 10mm of padding
            pdf.addImage(northArrowImg, 'png', dims[0] - 10, 15, northArrowImg.width * 0.1, northArrowImg.height * 0.1)
            pdf.text(`1:${self.scale}`, dims[0] - 12, dims[1] + (5 / 200 * self.resolution))

            self.pdf = pdf

            let tasks = []
            const pdfDataUrl = pdf.output('datauristring')
            let rawData = pdfDataUrl.replace(/^data:application\/(pdf);filename=generated.pdf;base64,/, '') // extraxt only raw data from blob
            tasks.push(mediaService.uploadMedia(project.PrGuid, self.fileName, rawData, 'MapImages'))

            // Finish saving data
            window.Promise.all(tasks)
              .then((uploadMediaRes) => {
                uploadMediaRes = uploadMediaRes[0]
                self.loading = false
                self.resetOriginalMapSize(viewResolution)
                if (uploadMediaRes.data && ((uploadMediaRes.data.ResultCode === 'Upload_Succes') || (uploadMediaRes.data.ResultCode === 'Upload_Succes_With_Messages'))) {
                  self.uploaded = true
                } else {
                  popup.popup(this.$t('message.Status_Code') + ' [' + uploadMediaRes.data.ResultCode + ',' + uploadMediaRes.data.ResultMessage + ']')
                }
              })
              .catch(err => {
                self.loading = false
                self.handlerError(err)
                self.resetOriginalMapSize(viewResolution)
              })
          })
          .catch(function (err) {
            self.handlerError(err)
            self.resetOriginalMapSize(viewResolution)
          })
      })

      // Set print size
      this.mapRef.scaleLineRef.setDpi(this.resolution)
      this.mapRef.getTargetElement().style.width = width + 'px'
      this.mapRef.getTargetElement().style.height = height + 'px'
      this.mapRef.updateSize()
      this.mapRef.getView().setResolution(scaleResolution)
    },

    resetOriginalMapSize (viewResolution) {
      // Reset original map size
      this.mapRef.scaleLineRef.setDpi()
      this.mapRef.getTargetElement().style.width = ''
      this.mapRef.getTargetElement().style.height = ''
      this.mapRef.updateSize()
      this.mapRef.getView().setResolution(viewResolution)

      this.loading = false
    },

    close () {
      this.clearData()
      $('#modalPrintToScale').modal('hide')
    },

    handlerError (err) {
      popup.popup(err.body)
    }
  }
}
</script>

<style scoped lang="less">

  .scale {
    display: flex;
  }

  .scale-indicator {
    display: block;
    width: 7%;
    margin-right: 5px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .scale-input {
    width: calc(47% - 6px);
    margin-bottom: 0px !important;
  }

  .w-100 {
    width: 100%;
  }

  .print-to-scale-form {
    .form-control  {
      margin-bottom: 10px;
    }
  }

</style>
