var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header',{attrs:{"table-view-route":{ name: 'overview', params: { projectId: `${_vm.projectId}`, overviewId: '108' } },"graph-view-route":{
      name: 'dashboard',
      params: { projectId: `${_vm.projectId}`, dashboardId: '101' }
    }}}),_c('ti-dialog',{ref:"selectProjectDialog"},[_c('project-select-dialog')],1),_c('ti-dialog',{ref:"copyMoveSettingsDialog"},[_c('copy-move-settings-dialog')],1),_c('aside',{staticClass:"boxes"},[(_vm.workingObject)?_c('MeasurementPointBoxes',{ref:"measurementPointBoxes",attrs:{"projectId":_vm.projectId,"selectedMeasurementPointGuid":_vm.selectedMeasurementPointGuid}}):_vm._e()],1),_c('div',{staticClass:"measurementPointDetailsBox"},[_c('div',{staticClass:"measurementPointDetails",style:(_vm.mpLength)},[(_vm.workingObject && _vm.hasMeasurementPointGuid)?_c('editors',{attrs:{"screen":"frmMeasurementPointDetails"}}):_vm._e(),_c('div',{staticClass:"right-click-menu",attrs:{"id":"projectContextMenuPopupMp","title":_vm.$t('tooltips.duplicateMeasurementPoint')}},[(_vm.selectedElement.type === 'MEASUREMENTPOINT' && _vm.viewMenu)?_c('li',{on:{"click":_vm.duplicateMeasurementPoint}},[_vm._v(_vm._s(_vm.$t('project.DuplicateMeasurementPoint')))]):_vm._e()]),_c('div',{staticClass:"map"},[_c('div',{staticClass:"mapBar"},[_c('div',{staticClass:"closeMap",on:{"click":function($event){return _vm.closeMapRoute()}}},[_c('span',{staticClass:"glyphicon glyphicon-chevron-right"})])]),(_vm.mapOpen && _vm.projects.length > 0)?_c('open-layers'):_vm._e()],1),(_vm.hasMeasurementPointGuid)?[(_vm.layers && _vm.soilSamples && _vm.gaugingTubes && _vm.workingObject && _vm.workingObject.MpGuid)?_c('sync-scroll',{attrs:{"options":{
            layer: _vm.layers,
            soilSample: _vm.soilSamples,
            gaugingTubes: _vm.gaugingTubes,
            PrID: _vm.workingObject.PrID,
            MpGuid: _vm.workingObject.MpGuid
          }}}):_vm._e(),(_vm.finishings && _vm.workingObject)?_c('boxes',{attrs:{"options":{
            title: 'label.finishings',
            type: 'finishingsBox',
            businesskey: 'finishings',
            border: _vm.finishings.length > 0 ? 'border' : '',
            modal: 'fiModal',
            data: _vm.finishings,
            PrID: _vm.workingObject.PrID,
            MpGuid: _vm.workingObject.MpGuid,
            buttons: ['addButton'],
            enableBoxLink: true
          }}}):_vm._e(),(_vm.workingObject && _vm.currentMPComplexWaterSamples)?_c('div',_vm._l((_vm.currentMPComplexWaterSamples),function(item){return _c('water-bottle',{key:item.guid + '-water',attrs:{"template":{ data: item },"options":{
              type: 'WATER',
              PrID: _vm.workingObject.PrID,
              MpGuid: _vm.workingObject.MpGuid,
              MpName: _vm.workingObject.MpName,
              FtGuid: item.guid,
              FtID: item.id,
              data: _vm.currentMPComplexWaterSamples,
              showAddButton: true,
              enableBoxLink: true
            }}})}),1):_vm._e(),(_vm.workingObject)?_c('div',_vm._l((_vm.currentMPComplexSurfaceWaterSamples),function(item){return _c('water-bottle',{key:item.guid + '-surfacewater',attrs:{"template":{ data: item },"options":{
              type: 'SURFACEWATER',
              PrID: _vm.workingObject.PrID,
              MpGuid: _vm.workingObject.MpGuid,
              FtID: undefined,
              MpName: _vm.workingObject.MpName,
              data: _vm.currentMPComplexSurfaceWaterSamples,
              showAddButton: true,
              enableBoxLink: true
            }}})}),1):_vm._e(),(_vm.workingObject && _vm.currentMpComplexAirSamples)?_c('div',_vm._l((_vm.currentMpComplexAirSamples),function(item){return _c('water-bottle',{key:item.guid + '-air',attrs:{"template":{ data: item },"options":{
              type: 'AIR',
              PrID: _vm.workingObject.PrID,
              MpGuid: _vm.workingObject.MpGuid,
              MpName: _vm.workingObject.MpName,
              FtGuid: item.guid,
              FtID: item.id,
              data: _vm.currentMpComplexAirSamples,
              showAddButton: true,
              enableBoxLink: true
            }}})}),1):_vm._e(),(_vm.workingObject)?_c('div',_vm._l((_vm.currentMpComplexSurfaceAirSamples),function(item){return _c('water-bottle',{key:item.guid + '-surfaceair',attrs:{"template":{ data: item },"options":{
              type: 'SURFACEAIR',
              PrID: _vm.workingObject.PrID,
              MpGuid: _vm.workingObject.MpGuid,
              FtID: undefined,
              MpName: _vm.workingObject.MpName,
              data: _vm.currentMpComplexSurfaceAirSamples,
              showAddButton: true,
              enableBoxLink: true
            }}})}),1):_vm._e()]:_vm._e(),_c('map-modal'),_c('print-to-scale-modal')],2)]),_c('app-footer'),_c('nv-loading',{attrs:{"show":_vm.loading}}),(_vm.workingObject)?_c('BorelogSidePanel',{attrs:{"projectId":_vm.projectId,"measurementPointId":_vm.workingObject.MpID,"measurementPointGuid":_vm.selectedMeasurementPointGuid}}):_vm._e(),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }