<template>
  <div>
    <app-header
      :table-view-route="{ name: 'overview', params: { projectId: `${projectId}`, overviewId: '108' } }"
      :graph-view-route="{
        name: 'dashboard',
        params: { projectId: `${projectId}`, dashboardId: '101' }
      }"
    />
    <ti-dialog ref="selectProjectDialog">
      <project-select-dialog />
    </ti-dialog>

    <ti-dialog ref="copyMoveSettingsDialog">
      <copy-move-settings-dialog />
    </ti-dialog>
    <aside class="boxes">
      <MeasurementPointBoxes
        v-if="workingObject"
        ref="measurementPointBoxes"
        :projectId="projectId"
        :selectedMeasurementPointGuid="selectedMeasurementPointGuid"
      />
    </aside>
    <div class="measurementPointDetailsBox">
      <div class="measurementPointDetails" :style="mpLength">
        <editors v-if="workingObject && hasMeasurementPointGuid" screen="frmMeasurementPointDetails"></editors>
        <div id="projectContextMenuPopupMp" class="right-click-menu" :title="$t('tooltips.duplicateMeasurementPoint')">
          <li v-if="selectedElement.type === 'MEASUREMENTPOINT' && viewMenu" @click="duplicateMeasurementPoint">{{ $t('project.DuplicateMeasurementPoint') }}</li>
        </div>
        <div class="map">
          <div class="mapBar">
            <div class="closeMap" @click="closeMapRoute()">
              <span class="glyphicon glyphicon-chevron-right"></span>
            </div>
          </div>

          <open-layers v-if="mapOpen && projects.length > 0"></open-layers>
        </div>
        <template v-if="hasMeasurementPointGuid">
          <sync-scroll
            v-if="layers && soilSamples && gaugingTubes && workingObject && workingObject.MpGuid"
            :options="{
              layer: layers,
              soilSample: soilSamples,
              gaugingTubes: gaugingTubes,
              PrID: workingObject.PrID,
              MpGuid: workingObject.MpGuid
            }"
          ></sync-scroll>
          <boxes
            v-if="finishings && workingObject"
            :options="{
              title: 'label.finishings',
              type: 'finishingsBox',
              businesskey: 'finishings',
              border: finishings.length > 0 ? 'border' : '',
              modal: 'fiModal',
              data: finishings,
              PrID: workingObject.PrID,
              MpGuid: workingObject.MpGuid,
              buttons: ['addButton'],
              enableBoxLink: true
            }"
          ></boxes>
          <div v-if="workingObject && currentMPComplexWaterSamples">
            <water-bottle
              v-for="item in currentMPComplexWaterSamples"
              :key="item.guid + '-water'"
              :template="{ data: item }"
              :options="{
                type: 'WATER',
                PrID: workingObject.PrID,
                MpGuid: workingObject.MpGuid,
                MpName: workingObject.MpName,
                FtGuid: item.guid,
                FtID: item.id,
                data: currentMPComplexWaterSamples,
                showAddButton: true,
                enableBoxLink: true
              }"
            ></water-bottle>
          </div>
          <div v-if="workingObject">
            <water-bottle
              v-for="item in currentMPComplexSurfaceWaterSamples"
              :key="item.guid + '-surfacewater'"
              :template="{ data: item }"
              :options="{
                type: 'SURFACEWATER',
                PrID: workingObject.PrID,
                MpGuid: workingObject.MpGuid,
                FtID: undefined,
                MpName: workingObject.MpName,
                data: currentMPComplexSurfaceWaterSamples,
                showAddButton: true,
                enableBoxLink: true
              }"
            ></water-bottle>
          </div>
          <div v-if="workingObject && currentMpComplexAirSamples">
            <water-bottle
              v-for="item in currentMpComplexAirSamples"
              :key="item.guid + '-air'"
              :template="{ data: item }"
              :options="{
                type: 'AIR',
                PrID: workingObject.PrID,
                MpGuid: workingObject.MpGuid,
                MpName: workingObject.MpName,
                FtGuid: item.guid,
                FtID: item.id,
                data: currentMpComplexAirSamples,
                showAddButton: true,
                enableBoxLink: true
              }"
            ></water-bottle>
          </div>
          <div v-if="workingObject">
            <water-bottle
              v-for="item in currentMpComplexSurfaceAirSamples"
              :key="item.guid + '-surfaceair'"
              :template="{ data: item }"
              :options="{
                type: 'SURFACEAIR',
                PrID: workingObject.PrID,
                MpGuid: workingObject.MpGuid,
                FtID: undefined,
                MpName: workingObject.MpName,
                data: currentMpComplexSurfaceAirSamples,
                showAddButton: true,
                enableBoxLink: true
              }"
            ></water-bottle>
          </div>
        </template>
        <map-modal></map-modal>
        <print-to-scale-modal></print-to-scale-modal>
      </div>
    </div>

    <app-footer />
    <nv-loading :show="loading"></nv-loading>
    <BorelogSidePanel v-if="workingObject" :projectId="projectId" :measurementPointId="workingObject.MpID" :measurementPointGuid="selectedMeasurementPointGuid" />

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
  </div>
</template>

<script>
import $ from 'jquery'
import Vue from 'vue'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import config from '@/configurations/config.js'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import boxes from '@/components/boxes.vue'
import arrayFormater from '@/utils/arrayUtils'
import gapsRule from '@/utils/gapsRule'
import waterBottle from '@/components/box-controls/waterBottle'
import syncScroll from '@/components/box-controls/syncScroll'
import openLayers from '@/components/openLayers.vue'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { MEASUREMENT_POINT_TYPE } from '@/vuex/constants'
import mapModal from '@/components/modal/mapModal.vue'
import printToScaleModal from '@/components/modal/printToScaleModal.vue'

import TiDialog from '@/components/_shared/tiDialog.vue'
import ProjectSelectDialog from '@/components/modalSelects/projectSelectDialog.vue'
import CopyMoveSettingsDialog from '@/components/modalSelects/copyMoveSettingsDialog.vue'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import MeasurementPointBoxes from '@/components/boxes/measurementPointBoxes'

import BorelogSidePanel from '@/components/borelogSidePanel.vue'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const measurementPointService = new MeasurementPointService()

var self
// editors is used
// eslint-disable-next-line no-unused-vars
var editors

export default {
  name: 'measurementPoint',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    waterBottle,
    syncScroll,
    openLayers,
    mapModal,
    printToScaleModal,
    TiDialog,
    ProjectSelectDialog,
    CopyMoveSettingsDialog,
    BorelogSidePanel,
    MeasurementPointBoxes,
    ExportProjectPopup
  },
  data() {
    return {
      currentMPIndex: 0,
      currentMPComplexWaterSamples: [],
      currentMPComplexSurfaceWaterSamples: [],
      currentMpComplexAirSamples: [],
      currentMpComplexSurfaceAirSamples: [],
      loading: false,
      mapOpen: false,
      selectedMp: '',
      selectedId: '',
      viewMenu: false,
      exportPopupOpen: false
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    selectedMeasurementPointGuid() {
      return this.$route.params.measurementPointId
    },
    hasMeasurementPointGuid() {
      return this.selectedMeasurementPointGuid !== '0'
    },
    mpLength: function () {
      return (
        'width:' +
        (1235 +
          this.currentMPComplexWaterSamples.length * 420 +
          this.currentMPComplexSurfaceWaterSamples.length * 420 +
          this.currentMpComplexAirSamples.length * 420 +
          this.currentMpComplexSurfaceAirSamples.length +
          420) +
        'px'
      )
    },
    isNotProduction: function () {
      return window.location.host !== 'web.terraindex.com'
    },
    layers: {
      get() {
        return this.$store.state.layers
      },
      set(value) {
        this.$store.commit('setLayers', value)
      }
    },
    soilSamples: {
      get() {
        return this.$store.state.soilSamples
      },
      set(value) {
        this.$store.commit('setSoilSamples', value)
      }
    },
    gaugingTubes: {
      get() {
        return this.$store.state.gaugingTubes
      },
      set(value) {
        this.$store.commit('setGaugingTubes', value)
      }
    },
    finishings: {
      get() {
        return this.$store.state.finishings
      },
      set(value) {
        this.$store.commit('setFinishings', value)
      }
    },
    waterSamples: {
      get() {
        return this.$store.state.waterSamples
      },
      set(value) {
        this.$store.commit('setWaterSamples', value)
      }
    },
    surfaceWaterSamples: {
      get() {
        return this.$store.state.surfaceWaterSamples
      },
      set(value) {
        this.$store.commit('setWaterSamples', value)
      }
    },
    airSamples: {
      get() {
        return this.$store.state.airSamples
      },
      set(value) {
        this.$store.commit('setAirSamples', value)
      }
    },
    surfaceAirSamples: {
      get() {
        return this.$store.state.surfaceAirSamples
      },
      set(value) {
        this.$store.commit('setSurfaceAirSamples', value)
      }
    },
    bottles: {
      get() {
        return this.$store.state.bottles
      },
      set(value) {
        this.$store.commit('setBottles', value)
      }
    },
    measurementPoints: {
      get() {
        return this.$store.state.measurementPoints
      },
      set(value) {
        this.$store.commit('setMeasurementPoints', value)
      }
    },
    projects: {
      get() {
        return this.$store.state.projects
      },
      set(value) {
        this.$store.commit('setProjects', value)
      }
    },
    workingObject: {
      get() {
        return this.$store.state.workingObject
      },
      set(value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get() {
        return this.$store.state.workingObjectType
      },
      set(value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get() {
        return this.$store.state.workingObjectIndex
      },
      set(value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentProject: {
      get() {
        return this.$store.state.prTypeCode
      }
    },
    selectedElement: {
      get() {
        return this.$store.state.selectedElement
      },
      set(value) {
        this.$store.commit('setSelectedElement', value)
      }
    }
  },
  watch: {
    '$route.params.openMap': {
      handler: function () {
        if (this.$route.params.openMap === 'map') {
          this.openMap()
        } else {
          this.closeMap()
        }
      },
      immediate: true
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name !== 'measurementPoint') {
      this.measurementPoints = []
    }
    Vue.nextTick().then(() => {
      return this.loadData()
    })
    next()
  },
  async mounted() {
    self = this
    editors = self.$children.find((v) => v.$options.name === 'Editors')
    if (this.$route.params.openMap === 'map') this.openMap()
    if (this.hasMeasurementPointGuid) {
      await this.loadData()
    } else {
      this.measurementPoints = []
    }
  },
  methods: {
    async loadData() {
      this.loading = true
      let initiateCalls = []
      try {
        await this.updateSelectedMeasurementPoint()
      } catch (error) {
        console.log(error)
      }
      initiateCalls.push(this.$store.dispatch('fetchTblSubLocations', { PrID: this.workingObject.PrID }))
      initiateCalls.push(this.$store.dispatch('fetchLayers', { PrID: this.workingObject.PrID, MpGUID: this.workingObject.MpGuid }))
      initiateCalls.push(this.$store.dispatch('fetchJars', { PrID: this.workingObject.PrID, MpGUID: this.workingObject.MpGuid }))
      initiateCalls.push(this.$store.dispatch('fetchFinishings', { projectId: this.workingObject.PrID, MpGuid: this.workingObject.MpGuid }))
      initiateCalls.push(this.$store.dispatch('fetchGaugintubes', { projectId: this.workingObject.PrID, MpGuid: this.workingObject.MpGuid }))
      initiateCalls.push(this.$store.dispatch('fetchWaterSurfaceWaterAirSurfaceAirSamplesBottlesAndObservations', { projectId: this.workingObject.PrID, MpGuid: this.workingObject.MpGuid }))
      initiateCalls.push(this.$store.dispatch('fetchProject', { PrID: this.projectId }))

      return Promise.all(initiateCalls)
        .then(this.format)
        .then((res) => {
          return this.$store.dispatch('setActiveTitle', this.workingObject.MpName)
        })
        .then((res) => {
          // Scroll the active element into View if one is found
          // if (document.getElementsByClassName('contents active')[0]) {
          //   document.getElementsByClassName('contents active')[0].scrollIntoView({ block: 'center' })
          // }
          return this.$store.dispatch('setPrevTitle', (this.currentProject || {}).PrCode)
        })
        .then((res) => {
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    /*
     * Orders and positions Layers, Soil Samples and Gaugingtubes with gaps
     * Orders and positions Finishings, Water Samples and Bottles
     * */
    format(addGaps) {
      addGaps = addGaps !== false

      let layers = this.layers.filter((v) => v.type !== 'emptyBox')
      let soilSamples = this.soilSamples.filter((v) => v.type !== 'emptyBox')
      let gaugingTubes = this.gaugingTubes.filter((v) => v.type !== 'emptyBox')

      let result = gapsRule.gapTilesByLayers(layers, soilSamples, gaugingTubes)

      this.layers = result.layers || []
      this.soilSamples = result.soilSamples || []
      this.gaugingTubes = result.gaugingTubes || []

      if (!addGaps) {
        this.layers = this.layers.filter((v) => v.type !== 'emptyBox')
        this.soilSamples = this.soilSamples.filter((v) => v.type !== 'emptyBox')
        this.gaugingTubes = this.gaugingTubes.filter((v) => v.type !== 'emptyBox')
      }

      this.finishings.forEach((v) => (v.type = 'finishingsBox'))
      gapsRule.getSortData(this.finishings, 'FiFrom', 'FiTo')

      this.formatComplexWaterSamples()
      this.formatComplexSurfaceWaterSamples()
      this.formatComplexAirSamples()
      this.formatComplexSurfaceAirSamples()
    },
    formatComplexWaterSamples() {
      let complexWaterSampleList = []

      for (let gTube of this.gaugingTubes) {
        if (gTube.type === 'emptyBox') continue

        let complexWaterSample = {}
        complexWaterSample.id = gTube.FtID
        complexWaterSample.guid = gTube.FtGuid
        complexWaterSample.name = gTube.FtName
        complexWaterSample.from = gTube.FtFilterFrom
        complexWaterSample.to = gTube.FtFilterTo
        let subWaterSamples = this.waterSamples.filter((v) => v.FtID === gTube.FtID)
        if (subWaterSamples && subWaterSamples.length > 0) {
          for (let wSample of subWaterSamples) {
            let subBottles = this.bottles.filter((b) => b.WsID === wSample.WsID && !b.HasLocalID) // this makes sure bottles never get assigned twice
            if (subBottles) {
              let boIndex = 1
              for (let bottle of subBottles) {
                bottle.WsID = wSample.WsID
                bottle.BoID = boIndex.toString()
                bottle.HasLocalID = true
                boIndex++
              }
              wSample.bottles = subBottles
            } else {
              wSample.bottles = []
            }
          }
        } else {
          subWaterSamples = []
        }

        complexWaterSample.waterSamples = subWaterSamples.reverse()
        complexWaterSampleList.push(complexWaterSample)
      }
      this.currentMPComplexWaterSamples = complexWaterSampleList
    },
    formatComplexSurfaceWaterSamples() {
      let complexSurfaceWaterSampleList = []
      let complexSurfaceWaterSample = {}
      let subWaterSamples = this.surfaceWaterSamples
      if (subWaterSamples && subWaterSamples.length > 0) {
        // let wsIndex = 1
        for (let wSample of subWaterSamples) {
          let subBottles = this.bottles.filter((b) => b.WsID === wSample.WsID && !b.HasLocalID) // this makes sure bottles never get assigned twice

          if (subBottles) {
            let boIndex = 1
            for (let bottle of subBottles) {
              bottle.WsID = wSample.WsID
              bottle.BoID = boIndex.toString()
              bottle.HasLocalID = true
              boIndex++
            }
            wSample.bottles = subBottles
          } else {
            wSample.bottles = []
          }
          // wsIndex++
        }
      } else {
        subWaterSamples = []
      }
      complexSurfaceWaterSample.waterSamples = subWaterSamples.reverse()
      complexSurfaceWaterSampleList.push(complexSurfaceWaterSample)

      this.currentMPComplexSurfaceWaterSamples = complexSurfaceWaterSampleList
    },
    formatComplexAirSamples() {
      let complexWaterSampleList = []

      for (let gTube of this.gaugingTubes) {
        if (gTube.type === 'emptyBox') continue

        let complexWaterSample = {}
        complexWaterSample.id = gTube.FtID
        complexWaterSample.guid = gTube.FtGuid
        complexWaterSample.name = gTube.FtName
        complexWaterSample.from = gTube.FtFilterFrom
        complexWaterSample.to = gTube.FtFilterTo
        let subWaterSamples = this.airSamples.filter((v) => v.FtID === gTube.FtID)
        if (subWaterSamples && subWaterSamples.length > 0) {
          for (let wSample of subWaterSamples) {
            let subBottles = this.bottles.filter((b) => b.WsID === wSample.WsID && !b.HasLocalID) // this makes sure bottles never get assigned twice
            if (subBottles) {
              let boIndex = 1
              for (let bottle of subBottles) {
                bottle.WsID = wSample.WsID
                bottle.BoID = boIndex.toString()
                bottle.HasLocalID = true
                boIndex++
              }
              wSample.bottles = subBottles
            } else {
              wSample.bottles = []
            }
          }
        } else {
          subWaterSamples = []
        }

        complexWaterSample.waterSamples = subWaterSamples.reverse()
        complexWaterSampleList.push(complexWaterSample)
      }
      this.currentMpComplexAirSamples = complexWaterSampleList
    },
    formatComplexSurfaceAirSamples() {
      let complexSurfaceWaterSampleList = []
      let complexSurfaceWaterSample = {}
      let subWaterSamples = this.surfaceAirSamples
      if (subWaterSamples && subWaterSamples.length > 0) {
        // let wsIndex = 1
        for (let wSample of subWaterSamples) {
          let subBottles = this.bottles.filter((b) => b.WsID === wSample.WsID && !b.HasLocalID) // this makes sure bottles never get assigned twice

          if (subBottles) {
            let boIndex = 1
            for (let bottle of subBottles) {
              bottle.WsID = wSample.WsID
              bottle.BoID = boIndex.toString()
              bottle.HasLocalID = true
              boIndex++
            }
            wSample.bottles = subBottles
          } else {
            wSample.bottles = []
          }
          // wsIndex++
        }
      } else {
        subWaterSamples = []
      }
      complexSurfaceWaterSample.waterSamples = subWaterSamples.reverse()
      complexSurfaceWaterSampleList.push(complexSurfaceWaterSample)

      this.currentMpComplexSurfaceAirSamples = complexSurfaceWaterSampleList
    },

    setActiveMeasurementPoint() {
      // Find the index of the current measurement point
      return new Promise((resolve, reject) => {
        if (this.selectedMeasurementPointGuid !== '0') {
          this.measurementPoints.forEach((measurementPoint) => (measurementPoint.isActive = false))
          let selectedMeasurementPoint = this.measurementPoints.find((measurementPoint) => measurementPoint.MpGuid === this.selectedMeasurementPointGuid)
          if (!selectedMeasurementPoint) return
          this.currentMPindex = this.measurementPoints.findIndex((measurementPoint) => measurementPoint.MpGuid === this.selectedMeasurementPointGuid)
          this.workingObjectIndex = this.measurementPoints.findIndex((measurementPoint) => measurementPoint.MpGuid === this.selectedMeasurementPointGuid)
          this.workingObject = selectedMeasurementPoint
          this.workingObjectType = MEASUREMENT_POINT_TYPE
          this.workingObject.isActive = true
        } else {
          this.loading = false // no measurment point, just show the map
        }
        resolve()
      })
    },
    closeMapRoute() {
      this.$router.push('/project/' + this.projectId + '/measurementPoint/' + this.selectedMeasurementPointGuid)
    },
    closeMap() {
      this.mapOpen = false
      $('.map').css({
        transform: 'translate(100%)'
      })
    },
    openMap() {
      this.mapOpen = true
      $('.map').css({
        transform: 'translate(0)'
      })
    },
    async updateSelectedMeasurementPoint() {
      // Get the measurementpoints if there are none
      await measurementPointService.getMeasurementPoints(this.projectId).then(
        (measurementPointRes) => {
          this.measurementPoints = arrayFormater.formatArray(measurementPointRes.tblMeasurementPoints)
          this.projects = arrayFormater.formatArray(measurementPointRes.tblProjects)
        },
        (error) => {
          console.error(error)
        }
      )

      return this.setActiveMeasurementPoint()
    },
    save() {
      if (this.$store.state.workingObject) {
        this.loading = true
        return workingObjectUtils
          .pushWorkingObjectToDataService()
          .then((res) => {
            self.loading = false
            self.measurementPoints = res
            return true
          })
          .catch((err) => {
            self.loading = false
            console.error(err)
          })
      } else {
        return new Promise(function (resolve) {
          resolve(true)
        })
      }
    },
    async generateReport() {
      if (this.$store.state.workingObjectChanged) {
        await this.save()
      }
      window.open(config.apps.report + this.projectId, '_blank').focus()
    },
    deleteMeasurementPoint() {
      this.$refs.measurementPointBoxes.toggleDeleteMeasurementPointPopup(this.selectedMeasurementPointGuid)
    },
    getMpGuidOnSelectContextMenu(MpGuid, MpID) {
      this.openRightClickMenu()
      this.selectedMp = MpGuid
      this.selectedId = MpID
    },
    async duplicateMeasurementPoint() {
      this.$refs.measurementPointBoxes.toggleDuplicateMeasurementPointPopup(this.selectedMeasurementPointGuid)
    },
    closeRightClickMenu() {
      this.viewMenu = false
    },
    openRightClickMenu() {
      this.viewMenu = true
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>
<style>
.measurementPointDetailsBox {
  overflow-y: hidden;
  height: calc(100vh - 120px);
}
.measurementPointDetails {
  min-width: 1235px;
}
.map {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 60px;
  left: 420px;
  z-index: 11;
  transform: translate(100%);
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
}
.mapBar {
  width: 50px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #515151;
  height: 100%;
  float: left;
}
.closeMap {
  font-size: 30px;
  color: #eee;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
@media (max-width: 640px) {
  .measurementPointDetails .edit-panel .input-group.input-left {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .measurementPointDetails .editor-panel {
    width: calc(100vw - 410px);
  }
}
.boxes .boxesBody {
  background-color: #515151;
  color: white;
}
.modal-content {
  color: black;
}
</style>
