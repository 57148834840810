var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sync-scroll-box"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("label.layers")))]),_c('a',{staticClass:"flatbutton layer",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.open('layer')}}},[_c('span',{staticClass:"glyphicons glyphicons-plus"})])]),_c('div',{staticClass:"title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("label.soilSamples")))]),_c('a',{staticClass:"flatbutton soil-sample",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.open('soilSample')}}},[_c('span',{staticClass:"glyphicons glyphicons-plus"})])]),_c('div',{staticClass:"title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("label.gaugingTubes")))]),_c('a',{staticClass:"flatbutton gauging-tube",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.open('gaugingTubes')}}},[_c('span',{staticClass:"glyphicons glyphicons-plus"})])])]),_c('div',{staticClass:"scroll-body",attrs:{"data-simplebar":""}},[_c('boxes',{attrs:{"options":{
    type:'layerBox',
    businesskey:'layer',
    modal:'laModal',
    data: _vm.options.layer,
    PrID: _vm.options.PrID,
    isfieldRequest: true,
    fieldname: 'LaSoilType',
    isMultiple: true,
    MpGuid: _vm.options.MpGuid,
    enableBoxLink: true,
    disableSort: true,}}}),_c('boxes',{attrs:{"options":{
    type:'soilSampleBox',
    businesskey:'soilSample',
    modal:'saModal',
    data: _vm.options.soilSample,
    PrID: _vm.options.PrID,
    MpGuid: _vm.options.MpGuid,
    enableBoxLink: true,
    disableSort: true,}}}),_c('boxes',{attrs:{"options":{
    type:'gaugingTubesBox',
    businesskey:'gaugingTubes',
    modal:'gaModal',
    border: _vm.options.gaugingTubes.length>0 ?'border' : '',
    data: _vm.options.gaugingTubes,
    PrID: _vm.options.PrID,
    MpGuid: _vm.options.MpGuid,
    enableBoxLink: true,
    disableSort: true,}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }