<template>
  <!-- eslint-disable vue/valid-v-model -->
  <div id="borelog-container">
    <div
      class="sidebutton clickable"
      @click="setPanelVisible(true)"
    >
      <span
        id="sidePanelOpenIcon"
        class="glyphicons glyphicons-newspaper"
      ></span>
    </div>
    <div
      v-if="displayBorelogSidePanel"
      class="sidePanel"
    >
      <div id="side-panel-body">
        <div id="boreLogHeader">
          <span
            id="newspanel-close"
            class="glyphicon glyphicon-remove header-item clickable"
            @click="setPanelVisible(false)"
          />
          <span
            id="newspanel-header"
            class="header-item"
          >{{ $t('label.borelog') }}</span>
          <span
            id="newspanel-refresh"
            :disabled="source !== ''"
            style="float: right"
            class="glyphicon glyphicon-refresh header-item clickable"
            @click="generatePreview(true)"
          ></span>
          <DxSelectBox
            v-if="reportsAvailable"
            v-model:value="selectBoreLog"
            :disabled="loading"
            :items="availableReports"
            :search-enabled="true"
            search-mode="contains"
            search-expr="TemplateName"
            display-expr="TemplateName"
            value-expr="TemplateID"
            :placeholder="$t('borelogSidePanel.dropdownPlaceholder')"
            @value-changed="updateSetting"
          />
          <h4 v-else>{{ $t('borelogSidePanel.retrievingAllBorelog') }}</h4>
        </div>
        <div class="header-rule" />
        <div
          v-if="reportsAvailable"
          class="borelog-preview-container"
        >
          <div
            v-if="reportsAvailable"
            class="borelog-preview-image-container"
          >
            <!-- <span class="glyphicon glyphicon-remove reset-pan-zoom-button clickable" @click.stop="onResetPanZoom" /> -->
            <img
              :key="source"
              class="borelog-preview-image"
              :src="source"
              style="max-width: 33vw;"
            />
          </div>
          <!-- <PanZoom v-if="reportsAvailable" :options="panZoomOptions" @init="onInitPanZoom">

          </PanZoom> -->
          <div v-else>
            <div v-if="error">
              <h4>{{ $t('borelogSidePanel.errorBorelog') }}</h4>
            </div>
            <div v-else-if="loading">
              <h4>{{ $t('borelogSidePanel.generating') }}</h4>
            </div>
            <div v-else>
              <h4>{{ $t('borelogSidePanel.selectToShow') }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box'
import cookie from 'vue-cookie'
import sortUtils from '@/utils/sortUtils'

export default {
  name: 'BorelogSidePanel',
  components: {
    DxSelectBox
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    measurementPointId: {
      type: String,
      required: true,
      default: ''
    },
    measurementPointGuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displayBorelogSidePanel: false,
      source: '',
      error: false,
      availableReports: [],
      selectBoreLog: null,
      loading: false,
      settingName: 'BoreLogProfileIdForWebPreview',
      panZoomInstance: null
    }
  },
  watch: {
    selectBoreLog: {
      handler: function () {
        this.generatePreview()
      },
      immediate: false
    },
    selectBordisplayBorelogSidePaneleLog: {
      handler: function () {
        this.generatePreview()
      },
      immediate: false
    },
    measurementpointIdentifiers: {
      handler: function () {
        this.generatePreview()
      },
      immediate: false
    },
    panelVisible: {
      handler: function () {
        this.generatePreview()
      }
    }
  },
  methods: {
    generatePreview(forceReload) {
      if (!this.projectId || !this.measurementPointId || !this.selectBoreLog || !this.displayBorelogSidePanel || !this.measurementPointGuid || this.loading) return
      this.loading = true
      this.error = false
      this.source = ''
      const boreLogSettings = this.availableReports.find((borelog) => borelog.TemplateID === this.selectBoreLog)
      this.$store
        .dispatch('generateBorelogPreview', {
          templateID: this.selectBoreLog,
          projectId: this.projectId,
          measurementPointId: this.measurementPointId,
          settings: boreLogSettings.toString(),
          measurementPointGuid: this.measurementPointGuid,
          templateId: this.selectBoreLog,
          forceReload: forceReload
        })
        .then((borelogImage) => {
          this.source = `data:image/PNG;base64,${borelogImage}`
          this.loading = false
        })
        .catch((error) => {
          this.error = true
          this.loading = false
          if (error.status) console.error('Status: ', error.status, 'Error', error.data.Message)
          else console.error(error.stack)
        })
    },
    setPanelVisible(visibility) {
      this.displayBorelogSidePanel = visibility
    },
    updateSetting(event) {
      this.$store.dispatch('updateSetting', {
        settingName: this.settingName,
        settings: event.value
      })
    },
    onInitPanZoom(panZoomInstance) {
      this.panZoomInstance = panZoomInstance
    },
    onResetPanZoom() {
      this.panZoomInstance.zoomAbs(0, 0, 1)
      this.panZoomInstance.moveTo(0, 0)
    }
  },
  computed: {
    panelVisible() {
      return this.displayBorelogSidePanel
    },
    licenseNumber() {
      return cookie.get('licensenumber')
    },
    reportsAvailable() {
      return this.availableReports?.length > 0
    },
    measurementpointIdentifiers() {
      return `${this.projectId}-${this.measurementPointId}`
    },
    panZoomOptions() {
      return {
        maxZoom: 2,
        minZoom: 0.5,
        bounds: false,
        zoomDoubleClickSpeed: 1
      }
    }
  },
  async created() {
    const promises = [this.$store.dispatch('getSettingByName', { settingName: this.settingName }), this.$store.dispatch('getAllBorelogsNamesForLicense', { licenseNumber: this.licenseNumber })]

    await Promise.all(promises).then((result) => {
      const [settingResponse, availableReports] = result
      this.availableReports = availableReports.sort((firstReport, secondReport) => sortUtils.naturalSort(firstReport.TemplateName, secondReport.TemplateName))

      if (!settingResponse || !settingResponse.data || settingResponse.data.ResultCode !== 'Succes') return

      const settingValue = settingResponse.data.SettingValue

      if (!settingValue || settingValue === '') return

      const foundReport = this.availableReports.find((borelog) => borelog.TemplateID.toString() === settingValue)

      if (foundReport) this.selectBoreLog = parseInt(settingValue)
    })
  }
}
</script>

<style scoped lang="less">
.header-rule {
  background-color: white;
  width: 100%;
  height: 2px;
}

#sidePanelOpenIcon {
  color: white;
  font-size: 30px;
}

.sidebutton {
  width: 50px;
  height: 50px;
  background-color: #67ac45;
  position: absolute;
  top: 200px;
  right: 0px;
  border-radius: 25% 0% 0% 25%;
  display: flex;
  align-items: center;
}

#side-panel-body {
  display: flex;
  padding: 10px;
  color: white;
  flex-direction: column;
  height: 100%;
  row-gap: 10px;
}

.header-item {
  color: white;
  padding: 5px;
  font-size: 20px;
  margin-bottom: 5px;
}

.sidePanel {
  position: absolute;
  width: 35vw;
  min-width: 300px;
  top: 60px;
  margin-bottom: 60px;
  height: calc(100vh - 120px);
  background-color: rgb(105, 105, 105);
  right: 0;
  z-index: 1500;
}

.borelog-preview-container {
  width: auto;
  height: auto;

  .vue-pan-zoom-item {
    width: auto;
    height: auto;

    .borelog-preview-image-container {
      .borelog-preview-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border: 1px black solid;

        &:hover {
          cursor: grab;
        }

        &:active {
          cursor: grabbing;
        }
      }

      .reset-pan-zoom-button {
        font-size: 20px;
        color: black;
        position: absolute;
        right: 0;
      }
    }
  }
}

.clickable:hover {
  cursor: pointer;
}</style>
