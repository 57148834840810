<template>
  <div id="modalSaveMapImage" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">{{$t("map.SaveMapImage")}}</h4>
        </div>
        <div class="modal-body">
          <p>{{$t("map.SaveMapImageHelp", {0: fileName})}}</p>
          <img v-if="imageBlob" v-bind:src="imageBlob" width="100%" />
        </div>
        <div class="modal-footer">
          <button class="btn" @click="save" >{{loadingText}}</button>
          <button class="btn" @click="close" >{{$t("map.CloseButton")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { bus } from '@/bus.js'
import config from '@/configurations/config.js'
import Popup from '@/utils/popup'
import { MediaService } from '@/services/MediaWS/mediaService'
const mediaService = new MediaService()

let popup = Popup[config.platform].default

export default {
  name: 'mapModal',
  props: ['functions', 'option'],

  data () {
    return {
      loading: false,
      imageBlob: null, // map image blob
      fileName: null
    }
  },

  computed: {
    projects: {
      get () {
        return this.$store.state.projects
      },
      set (value) {
        this.$store.commit('setProjects', value)
      }
    },
    loadingText: {
      get () {
        return this.loading ? (this.$t('other.loading') + '...') : this.$t('map.SaveButton')
      }
    }
  },

  mounted () {
    let self = this
    bus.$on('saveMapImageEvent', function (inputBlob) {
      self.imageBlob = inputBlob
      self.fileName = 'map_' + moment(new Date()).format('YYYYMMDD_kkmmss') + '.png'
      $('#modalSaveMapImage').modal('show')
    })
  },

  methods: {
    clearData () {
      this.imageBlob = null
      this.loading = false
      this.fileName = null
    },

    save () {
      if (this.loading) {
        return false
      }
      this.loading = true
      let self = this
      // find project guid
      let project = this.projects.find((pr) => { return pr.PrID === this.$route.params.projectId })
      if (!project) {
        popup.popup(this.$t('map.MapImageError'))
        return false
      }
      let tasks = []
      let rawData = this.imageBlob.replace(/^data:image\/(png|jpg);base64,/, '') // extraxt only raw data from blob
      tasks.push(mediaService.uploadMedia(project.PrGuid, this.fileName, rawData, 'MapImages'))
      window.Promise.all(tasks)
        .then((uploadMediaRes) => {
          uploadMediaRes = uploadMediaRes[0]
          self.loading = false
          if (uploadMediaRes.data && ((uploadMediaRes.data.ResultCode === 'Upload_Succes') || (uploadMediaRes.data.ResultCode === 'Upload_Succes_With_Messages'))) {
            $('#modalSaveMapImage').modal('hide')
          } else {
            popup.popup(this.$t('message.Status_Code') + ' [' + uploadMediaRes.data.ResultCode + ',' + uploadMediaRes.data.ResultMessage + ']')
          }
        })
        .catch(err => {
          self.loading = false
          self.handlerError(err)
        })
    },

    close () {
      this.clearData()
      $('#modalSaveMapImage').modal('hide')
    },

    handlerError (err) {
      popup.popup(err.body)
    }
  }
}
</script>
